@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap');

@tailwind base;

@tailwind components;

@tailwind utilities;

/*@import url('https://fonts.googleapis.com/css?family=Teko&display=swap');*/

.splide__slide__container {
    color: white;
    font-weight: bold;
}

nav {
    white-space: nowrap;
    background: #37474F;
    z-index: 10;
}

nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

/* Only stick if you can fit */
@media (min-height: 300px) {
    nav ul {
        position: sticky;
        top: 0;
    }
}

nav ul li a {
    display: block;
    padding: 0.5rem 1rem;
    color: white;
    text-decoration: none;
}

nav ul li a.current {
    background: black;
}

section {
    padding: 0 2rem;
}

.product {
    position: relative;
    height: 300px;
}

.product:hover {
    border: 1px solid #ccc;
    border-radius: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.product-name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-left: -.5rem;
}

.price {
    position: absolute;
    bottom: 0;
}

.product-price {
    font-weight: bold;
    margin-left: -.5rem;
}

.sticky + .page {
    margin-top: 65px;
}

@media only screen and (max-width: 768px) {
    section {
        padding: 0.5rem;
    //margin: 0 0 2rem 0;
    }
}

footer {
    background: #607D8B;
    padding: 5rem 1rem;
}

.alergeno {
    transition: all .2s ease-in-out;
    background: white;
}

.alergeno span {
    display: none;
    background: white;
    /*border: 1px solid #000;*/
}

.alergeno:hover {
    transform: scale(2.2);
}

.alergeno:hover span {
    display: block;
}

.heading {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.heading:after {
    content: '';
    border-top: 4px solid;
    margin: 0 20px 0 0;
    flex: 1 0 20px;
}

.heading:after {
    margin: 0 0 0 20px;
}

.clipped {
    clip-path: polygon(0% 30%, 20% 0%, 100% 0%, 100% 70%, 80% 100%, 0% 100%);
    transition: clip-path 1s;
}

.clipped:hover {
    clip-path: polygon(0% 40%, 30% 0%, 100% 0%, 100% 60%, 70% 100%, 0% 100%);
}

.header-image {
    margin: auto;
}

body {
    margin: 0;
    font-size: 28px;
    font-family: 'Open Sans', sans-serif;
}

.header {
    background-color: #f1f1f1;
    padding: 30px;
    text-align: center;
}

#navbar {
    overflow: hidden;
    background-color: #333;
}

#navbar a {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
}

#navbar a:hover {
    background-color: #ddd;
    color: black;
}

#navbar a.active {
    background-color: #4CAF50;
    color: white;
}

.content {
    padding: 16px;
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
}

.sticky + .content {
    padding-top: 60px;
}

.flip-card {
    background-color: transparent;
    height: 250px;
    overflow: hidden;
    perspective: 1000px;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}


.flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}

.flip-card-front {
    color: black;
    position: relative;
}

.flip-card-front .items {
    position: absolute;
    top: 40px;
    right: 0;
    color: white;
    background-color: #00000075;
    padding: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.flip-card-front .no-available {
    position: absolute;
    top: 100px;
    font-size: 35px;
    left: 0;
    color: white;
    background-color: #00000098;
    padding: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.flip-card-front img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.add-dish-fast {
    position: absolute;
    right: 20px;
    bottom: 10px;
    background-color: #fdca10;
    border: 2px solid #e04591;
    border-radius: 5px;
    padding: 5px;
    color: #e04591;
    font-weight: 900;
    font-size: 12px;
}

@media (max-width: 640px) {
    .flip-card {
        height: 150px;
    }
    #navbar li {
        display: inline-block;
    }
    #navbar ul {
        width: 100%;
        overflow-x: scroll;
    }
    .product {
        height: 240px;
    }

    .flip-card-front .no-available {
        position: absolute;
        top: 60px;
        left: 0;
        font-size: 20px;
        color: white;
        background-color: #00000098;
        padding: 5px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
}
